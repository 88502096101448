import {
  DependencyList,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useHandler } from "./hook";

export interface LoadState<T> {
  error?: any;
  pending: boolean;
  value?: T;
}

export interface LoadIteratorState<T> {
  readonly error?: any;
  readonly pending: boolean;
  readonly value: ReadonlyArray<T>;
}

export interface Loader<T> {
  (abort: AbortSignal): Promise<T>;
}

export interface LoaderWithInput<I, T> {
  (input: I, abort: AbortSignal): Promise<T>;
}

export class InvalidIteratorState extends Error {}

export function useLoad<T>(fn: Loader<T>, deps: DependencyList): LoadState<T> {
  const [state, setState] = useState<LoadState<T>>({ pending: false });

  useEffect(() => {
    const abortController = new AbortController();
    setState((state) => ({ ...state, pending: true }));
    fn(abortController.signal).then(
      (value) => setState({ pending: false, value }),
      (error) => {
        if (
          !(
            error.message.includes("Request aborted for RPC method") ||
            error.code === "ERR_CANCELED" ||
            error.message === "Another request is in flight"
          )
        ) {
          setState({ pending: false, error });
        }
      },
    );
    return () => {
      abortController.abort();
      setState((state) => ({ ...state, pending: false }));
    };
    // The way useLoad() is designed, we have no choice but to trust that the user gave us the correct deps for fn().
    // We could fix this by marking useLoad() as a custom hook, and then exhaustive-deps would enforce that for us.
    // https://www.npmjs.com/package/eslint-plugin-react-hooks#advanced-configuration
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return state;
}

export function useDebouncedLoad<T>(
  fn: Loader<T>,
  delay: number,
  deps: DependencyList,
): LoadState<T> {
  const [state, setState] = useState<LoadState<T>>({ pending: false });
  useEffect(() => {
    const abortController = new AbortController();
    setState((state) => ({ ...state, pending: true }));
    const timeout = setTimeout(() => {
      fn(abortController.signal).then(
        (value) => setState({ pending: false, value }),
        (error) => {
          if (
            !(
              error.message.includes("Request aborted for RPC method") ||
              error.code === "ERR_CANCELED" ||
              error.message === "Another request is in flight"
            )
          ) {
            setState({ pending: false, error });
          }
        },
      );
    }, delay);
    return () => {
      abortController.abort();
      clearTimeout(timeout);
      setState((state) => ({ ...state, pending: false }));
    };
    // The way useLoad() is designed, we have no choice but to trust that the user gave us the correct deps for fn().
    // We could fix this by marking useLoad() as a custom hook, and then exhaustive-deps would enforce that for us.
    // https://www.npmjs.com/package/eslint-plugin-react-hooks#advanced-configuration
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return state;
}

export interface IteratorNext<T> {
  (value?: T): void;
}

export function useTriggerLoad<T>(
  fn: Loader<T>,
): [LoadState<T | undefined>, (signal?: AbortSignal) => void] {
  const [trigger, setTrigger] = useState<symbol | null>(null);

  const cleanup = useRef<() => void>(() => {});

  useEffect(() => {
    return () => cleanup.current();
  }, []);

  const triggerResolve = useRef<() => void>(() => {});
  const triggerFn = useHandler((signal?: AbortSignal) => {
    cleanup.current();
    setTrigger(Symbol());

    if (signal) {
      const stop = () => setTrigger(null);
      signal.addEventListener("abort", stop);
      cleanup.current = () => signal.removeEventListener("abort", stop);
    } else {
      cleanup.current = () => {};
    }
    void new Promise<void>((resolve) => {
      triggerResolve.current = resolve;
    });
  });

  const load = useLoad(
    async (abort) => {
      if (!trigger) {
        return Promise.resolve(undefined);
      }
      const resolve = triggerResolve.current;
      try {
        return await fn(abort);
      } finally {
        resolve();
      }
    },
    [trigger],
  );

  return [load, triggerFn];
}

export function useLoader<T>(): [
  LoadState<T | undefined>,
  (loader: Loader<T>) => void,
] {
  const ref = useRef<Loader<T> | undefined>();

  const [load, trigger] = useTriggerLoad((abort) => ref.current!(abort));

  const fn = useHandler((loader: Loader<T>) => {
    ref.current = loader;
    trigger();
  });

  return [load, fn];
}

export function useTriggerLoadWithInput<I, T>(
  fn: LoaderWithInput<I, T>,
): [LoadState<T | undefined>, (input: I, signal?: AbortSignal) => void] {
  const [triggerWithInput, setTriggerWithInput] = useState<{ input: I } | null>(
    null,
  );

  const cleanup = useRef<() => void>(() => {});

  useEffect(() => {
    return () => cleanup.current();
  }, []);

  const triggerResolve = useRef<() => void>(() => {});
  const triggerFn = useHandler((input: I, signal?: AbortSignal) => {
    cleanup.current();
    setTriggerWithInput({ input });

    if (signal) {
      const stop = () => setTriggerWithInput(null);
      signal.addEventListener("abort", stop);
      cleanup.current = () => signal.removeEventListener("abort", stop);
    } else {
      cleanup.current = () => {};
    }
    void new Promise<void>((resolve) => {
      triggerResolve.current = resolve;
    });
  });

  const load = useLoad(
    async (abort) => {
      if (!triggerWithInput) {
        return Promise.resolve(undefined);
      }
      const resolve = triggerResolve.current;
      try {
        return await fn(triggerWithInput.input, abort);
      } finally {
        resolve();
      }
    },
    [triggerWithInput],
  );

  return [load, triggerFn];
}

// TODO Remove? Not actually used anywhere
export function useLoadIterable<T, N = void>(
  iterable: AsyncIterable<T> | null,
  deps: DependencyList,
): [
  LoadIteratorState<T>,
  IteratorNext<N> | undefined,
  (newState: LoadIteratorState<T>) => void,
] {
  const [iterator, setIterator] = useState<AsyncIterator<T> | null>(null);

  const [state, setState] = useState<LoadIteratorState<T>>({
    pending: false,
    value: [],
  });

  const ready = useRef(false);

  useEffect(() => {
    ready.current = true;
    setState({ pending: false, value: [] });
    if (!iterable) {
      return;
    }
    const iterator = iterable[Symbol.asyncIterator]();
    setIterator(iterator);
    return () => {
      void iterator.return?.();
    };
    // The way useLoadIterable() is designed, we have no choice but to trust that the user gave us the correct deps.
    // We could fix this by marking useLoadIterable() as a custom hook, and then exhaustive-deps would enforce that for us.
    // https://www.npmjs.com/package/eslint-plugin-react-hooks#advanced-configuration
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps, iterable]);

  const next = useCallback<IteratorNext<N>>(
    (value) => {
      if (!ready.current) {
        throw new InvalidIteratorState("Iterator is not available");
      }
      setState((state) => ({ ...state, pending: true }));
      ready.current = false;
      iterator!
        .next(<any>value)
        .then(({ value, done }) => {
          if (done) {
            setState((state) => ({ ...state, pending: false }));
            return;
          }
          setState((state) => ({
            pending: false,
            value: [...state.value, value],
          }));
          ready.current = true;
        })
        .catch((error) => {
          if (
            error.code !== "ERR_CANCELED" ||
            error.message !== "Another request is in flight"
          ) {
            setState((state) => ({ ...state, pending: false, error }));
          }
        });
    },
    [iterator],
  );

  const overwriteState = (newState: LoadIteratorState<T>) => {
    setState(newState);
  };

  return [state, ready.current && iterator ? next : undefined, overwriteState];
}
