import "./styles.css";
import "./styles.global.css";

import { IterableMap } from "@redotech/react-util/component";
import { useLoad } from "@redotech/react-util/load";
import { StyleRoot } from "@redotech/react-util/style";
import { createCommerceCloudRpcClient } from "@redotech/redo-commerce-cloud-server/rpc/schema";
import { memo, useEffect, useMemo, useState } from "react";
import { createRoot } from "react-dom/client";
import { useInterval } from "usehooks-ts";
import { CartCard } from "./cart-card";
import { ExchangeSelectBanner } from "./exchange-select.module";
import styleManager from "./style-manager";

const scriptUrl = new URL(
  (document.currentScript as HTMLScriptElement).src,
  location.href,
);

const params = new URLSearchParams(new URL(scriptUrl, location.href).search);

window.redoWidgetId = params.get("widget_id")!;

// TODO: Get BGZ to update their script tag URLs so we can get rid of this
const widgetIds = {
  "bodyguardz.com": "fcsuql0bisdah2r",
  "www.bodyguardz.com": "fcsuql0bisdah2r",
  "lander.com": "6429ss0d0wz08u1",
  "www.lander.com": "6429ss0d0wz08u1",
};

const widget_id =
  params.get("widget_id") || widgetIds[window.location.hostname];

if (!widget_id) {
  throw new Error("Widget ID not found");
}

const client = createCommerceCloudRpcClient({
  baseURL: new URL("https://commerce-cloud-server.getredo.com/rpc"),
  headers: {
    "x-widget-id": "fcsuql0bisdah2r",
  },
  defaultMethod: "GET",
});

const REDO_ELEMENT_SELECTOR = ".redo-card";

const App = memo(function App() {
  const [containers, setContainers] = useState<HTMLElement[]>([]);

  const config = useLoad(async (signal) => {
    return client.getStorefrontConfig(
      {
        widgetId: "fcsuql0bisdah2r",
      },
      {
        signal,
      },
    );
  }, []);

  useEffect(() => {
    const style = document.createElement("style");
    document.head.appendChild(style);
    if (
      config.value?.enabled &&
      config.value.hideProduct?.enabled &&
      config.value.hideProduct.selector
    ) {
      style.innerText = `${config.value.hideProduct.selector} { display: none; } `;
    }
    return () => {
      document.head.removeChild(style);
    };
  }, [config?.value]);

  const bannerContainer = useMemo(() => {
    const element = document.createElement("div");
    element.id = "redo-banner";
    document.body.appendChild(element);
    return element;
  }, []);

  const exchangeSelect =
    sessionStorage.getItem("redo.exchange-select") !== null && opener;

  useInterval(() => {
    if (exchangeSelect || !config.value?.enabled) {
      return;
    }
    const result: HTMLElement[] = [];
    const togglePlacement = config.value.togglePlacement || "before";
    document
      .querySelectorAll(config.value.toggleSelector)
      .forEach((container) => {
        let redoContainer: HTMLElement | null = null;
        switch (togglePlacement) {
          case "before":
          case "after":
            redoContainer =
              container.parentElement?.querySelector(REDO_ELEMENT_SELECTOR) ||
              null;
            break;
          case "prepend":
          case "append":
            redoContainer = container.querySelector(REDO_ELEMENT_SELECTOR);
            break;
        }
        if (!redoContainer) {
          redoContainer = document.createElement("div");
          redoContainer.className = "redo-card";
          switch (togglePlacement) {
            case "before":
              container.insertAdjacentElement("beforebegin", redoContainer);
              break;
            case "after":
              container.insertAdjacentElement("afterend", redoContainer);
              break;
            case "prepend":
              container.insertAdjacentElement("afterbegin", redoContainer);
              break;
            case "append":
              container.insertAdjacentElement("beforeend", redoContainer);
              break;
          }
        }
        result.push(redoContainer);
      });
    setContainers(result);
  }, 100);

  return (
    <>
      <IterableMap items={containers}>
        {(container) => (
          <StyleRoot container={container} styleManager={styleManager}>
            {config.value?.enabled ? <CartCard config={config.value} /> : null}
          </StyleRoot>
        )}
      </IterableMap>
      {exchangeSelect && (
        <StyleRoot container={bannerContainer} styleManager={styleManager}>
          <ExchangeSelectBanner />
        </StyleRoot>
      )}
    </>
  );
});

const startExchange = location.search.includes("redoExchangeSelect");
if (startExchange) {
  sessionStorage.setItem("redo.exchange-select", "");
}

const rootElement = document.createElement("div");
rootElement.id = "redo-commerce-cloud-widget";

const root = createRoot(rootElement);

root.render(<App />);
