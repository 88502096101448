// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../k8-opt-exec-ST-13d3ddad9198/bin/redo/commerce-cloud-extension/webpack.bin.runfiles/npm_css-loader_6.7.3-dc3fc578/files/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../k8-opt-exec-ST-13d3ddad9198/bin/redo/commerce-cloud-extension/webpack.bin.runfiles/npm_css-loader_6.7.3-dc3fc578/files/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1-0 {\n  border: 2px solid #333;\n  display: flex;\n  padding: 12px;\n  gap: 12px;\n}\n\n._1-1 {\n  font-size: 14px;\n}\n\n._1-2 {\n  font-size: 12px;\n  color: #7c8d8f;\n}\n\n._1-3 {\n  background: black;\n  color: white;\n  border: none;\n  padding: 4px 12px;\n  font-size: 12px;\n  cursor: pointer;\n}\n\n._1-4 {\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n}\n\n._1-5 {\n  justify-self: flex-end;\n  align-items: center;\n  margin-top: 6px;\n  display: flex;\n  justify-content: space-between;\n}\n\n._1-6 {\n  margin: -2px -4px;\n  max-width: unset;\n  width: 50px;\n}\n\n._1-7 {\n}\n", ""]);
// Exports
export var container = "_1-0";
export var title = "_1-1";
export var subtitle = "_1-2";
export var button = "_1-3";
export var main = "_1-4";
export var bottom = "_1-5";
export var logo = "_1-6";
export var logoContainer = "_1-7";
export default ___CSS_LOADER_EXPORT___;
